export const scrollToSection = async (sectionId: string, yOffset: number) => {
  await new Promise(() =>
    setTimeout(() => {
      const element = document.getElementById(sectionId)
      if (!element) return
      const scrollingYAxis =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset

      window.scroll({
        top: scrollingYAxis,
        behavior: 'smooth',
      })
    }, 500)
  )
}
